import Element, {ElementType} from "@/models/Element";
import Node from "@/models/Node";
import Page from "@/models/Page";
import RuleSet from "@/models/RuleSet";
import Block from "@/models/Block";

enum Injections {
  Emitter = 'emitter'
}

enum MittEvents {
  OpenLogic = 'open-logic',
}

type ActiveEditElement = Element | Node;
type ActiveEditElementType = typeof Block | typeof Page | typeof RuleSet | typeof Node;
enum ActiveEditElementTypeEnum {
  Block = ElementType.Block,
  Page = ElementType.Page,
  RuleSet = ElementType.RuleSet,
  Node = 'node'
}
const ACTIVE_EDIT_ELEMENT_TYPES = [Block, Page, Node, RuleSet];

const ACTIVE_EDIT_ELEMENT_TYPE_MAP: Map<ActiveEditElementType, ActiveEditElementTypeEnum> = new Map<ActiveEditElementType, ActiveEditElementTypeEnum>([
  [Block, ActiveEditElementTypeEnum.Block],
  [Page, ActiveEditElementTypeEnum.Page],
  [Node, ActiveEditElementTypeEnum.Node],
  [RuleSet, ActiveEditElementTypeEnum.RuleSet],
]);

type Ref = string
interface Referable {
  ref: Ref,
  getRefs(): Array<Ref>,
}

export {
  Injections,
  MittEvents,
  ActiveEditElement,
  ActiveEditElementType,
  ActiveEditElementTypeEnum,
  ACTIVE_EDIT_ELEMENT_TYPES,
  ACTIVE_EDIT_ELEMENT_TYPE_MAP,
  Referable,
  Ref,
}
