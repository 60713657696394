export default {
  a11y: {
    mainNav: 'Main navigation',
    listOfSurveyTemplates: 'List of survey templates',
    infoLabel: 'info',
    openQuestionsList: 'Open questions list',
    expandQuestion: 'Expand this question',
    collapseQuestion: 'Collapse this question',
    collapseAllQuestions: 'Collapse all questions on this page',
    editAnswerText: 'Edit answer text',
    openQuestionSettingsDialog: 'Open question settings dialog',
    openQuestionBulkAnswersDialog: 'Open bulk answers dialog',
    openQuestionQuotasDialog: 'Open quotas dialog',
    openQuestionBulkRowAnswersDialog: 'Open bulk row answers dialog',
    openQuestionBulkColumnAnswersDialog: 'Open bulk column answers dialog',
    openQuestionSuggestedAnswersDialog: 'Open suggested answers dialog',
    openQuestionAnswersOptionsDialog: 'Open answers options dialog',
    openQuestionSmartChoicesDialog: 'Open smart choices dialog',
    editLabel: 'Edit label',
    editName: 'Edit name',
    quotas: "Quotas",
    editQuotas: "Edit Quotas",
    editQuestionLabel: 'Edit question label',
    saveQuestionLabel: 'Save question label',
    cancelQuestionLabel: 'Cancel question label',
    closeDialog: 'Close dialog',
    deleteRule: 'Delete condition',
    increaseQuota: 'Increase quota',
    decreaseQuota: 'Decrease quota',
    answerActionsPopupBtn: 'Open answer actions popup',
    generateWordingReview: 'Generate wording review',
    closeAIAssistant: 'Close AI assistant',
    AIAssistantSuggestionsGroup: 'AI assistant suggestions group'
  }
}
