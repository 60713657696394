import Element from "@/models/Element";
import Page from "@/models/Page";
import Node from "@/models/Node";

export interface RandomizationItems {
  [ref: string]: {
    configuration: number
  }
}
export enum RandomizationMethod {
  Shuffle = 'shuffle',
  Reverse = 'reverse',
  None = 'none'
}

export class Randomization {
  constructor(public items: RandomizationItems = {},
              public methods: RandomizationMethod[] = [RandomizationMethod.Shuffle]) {}

  getRandomizedItems(): [string, {configuration: number}][] {
    return Object.entries(this.items).filter(([_key, value]) => value.configuration !== null);
  }

  hasReversedItems(): boolean {
    return this.methods.includes(RandomizationMethod.Reverse);
  }

  hasRandomizedItems(): boolean {
    return this.getRandomizedItems().length > 0;
  }

  static clearRandomization(element: Element) {
    if (element instanceof Page) {
      element.nodeRandomization = new Randomization();
    }
  }

  static setRandomization(element: Element, lockedRefs: {[ref: string]: boolean}) {
    if (element instanceof Page) {
      this.setPageRandomization(element, lockedRefs);
    }
  }

  private static setPageRandomization(page: Page, lockedRefs: {[ref: string]: boolean}) {
    const items = page.nodes
      .filter(node => !lockedRefs[node.ref])
      .reduce<RandomizationItems>((acc, node, index) => ({ ...acc, [node.ref]: { configuration: index + 1} }), {});
    page.nodeRandomization = new Randomization(items);
  }

  static getLockedItems(element: Element) {
    if (element instanceof Page) {
      return this.getPageLockedItems(element);
    }
  }

  private static getPageLockedItems(page: Page): {[ref: string]: boolean} {
    const lockedItems = Object.entries(page.nodeRandomization.items)
      .reduce((acc, [ref, value]) => value.configuration === null ? {...acc, [ref]: true} : acc, {});
   return page.nodes.reduce((acc, node) => {
     if (!(page.nodeRandomization.items[node.ref]?.configuration > 0)) {
       return {...acc, [node.ref]: true};
     }
     return acc;
   }, lockedItems);
  }

  static setReversion(element: Element, lockedRefs: {[ref: string]: boolean}) {
    if (element instanceof Page) {
      this.setPageReversion(element, lockedRefs);
    }
  }

  private static setPageReversion(page: Page, lockedRefs: {[ref: string]: boolean}) {
    const items = page.nodes
      .filter(node => !lockedRefs[node.ref])
      .reduce<RandomizationItems>((acc, node, index) => ({ ...acc, [node.ref]: { configuration: index + 1} }), {});
    page.nodeRandomization = new Randomization(items, [RandomizationMethod.Reverse]);
  }

  static reverseWithoutLockedItems(nodes: Node[], lockedItems: {[ref: string]: boolean}): Node[] {
    let unlockedIndex = 0;
    const nodesLength = nodes.length;
    const finalNodes = new Array(nodesLength);
    const reversedUnlockedItems = nodes.filter(node => !lockedItems[node.ref]).reverse();

    for (let i = 0; i < nodesLength; i++) {
      const node = nodes[i];
      finalNodes[i] = lockedItems[node.ref] ? node : reversedUnlockedItems[unlockedIndex++];
    }

    return finalNodes;
  }
}
