import Survey from "@/models/Survey";
import {uuid} from "@/utils/string";
import Quota from "@/models/Quota";

export default class QuotaGroup {
  quotas: Quota[];

  constructor(
    survey: Survey,
    public ref: string = null,
    public name: string = null,
    quotas: Quota[] = null,
  ) {
    this.ref = ref ?? uuid();
    this.quotas = [];
    this.addQuotas(quotas ?? [], survey);
  }

  addQuota(quota: Quota, survey: Survey, index: number = null) {
    if (index !== null) {
      this.quotas.splice(index, 0, quota);
    } else {
      this.quotas.push(quota);
    }
  }

  deleteQuota(quota: Quota) {
    const index = this.getQuotaIndex(quota);
    if (index > -1) {
      this.quotas.splice(index, 1);
    }
  }

  addQuotas(quotas: Quota[], survey: Survey) {
    quotas.forEach(quota => {
      this.addQuota(quota, survey);
    })
  }

  getTotalAllocated(): number {
    return this.quotas?.reduce((total, quota) => total + parseFloat(String(quota.count)) || 0, 0);
  }

  getQuotaByRef(ref: string): Quota | null {
    return this.quotas.find(el => el.ref === ref ?? null) ?? null;
  }

  getQuotaIndex(quota: Quota): number {
    return this.getQuotaIndexByRef(quota.ref);
  }

  getQuotaIndexByRef(ref: string): number {
    return this.quotas.findIndex(el => el.ref === ref);
  }
}
