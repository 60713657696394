/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import '@maru/hub-vuetify-components/style' // imports Maru style rules
import './styles/settings.scss' //imports global styles for this project only
import '@/styles/global_inputs.scss'
import '@/styles/global_dialogs.scss'
import '@/styles/global_tooltip.scss'
import MRichTextEditor from "@maru/hub-vuetify-components/m-rich-text-editor";
import MDraggable from "@maru/hub-vuetify-components/m-draggable";
import { DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import { createApp } from '@maru/vuetify-theme'
import App from './App.vue'

// Plugins
import {registerPlugins, registerComponents, registerDirectives} from '@/plugins'

const app = createApp(App);

import "@/plugins/pendo"

registerPlugins(app)
registerComponents(app)
registerDirectives(app)
app.component('MRichTextEditor', MRichTextEditor)
app.component('MDraggable', MDraggable);
app.component('VueDatePicker', DatePicker)

app.mount('#app')
