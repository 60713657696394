export default {
  labels: {
    na: 'N/A'
  },
  alert: {
    error: 'Error',
    warning: 'Warning!',
    description: 'Please fill out all the required fields with valid values',
    loading: 'Loading...',
    couldNotLoad: 'Could not load app - please start from the homepage'
  },
  cancel: "Cancel",
  delete: "Delete",
  remove: 'Remove',
}
